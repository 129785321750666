import Container from 'react-bootstrap/Container'
import MetaTags from 'react-meta-tags'
import React from 'react'

const ThankYou = () => {
  return (
    <div>
      <MetaTags>
        <title>
          Thank you for your message | Solar Panel Retailer and Installer
        </title>
        <meta name='description' content='Thank you page' />
        <meta name='robots' content='noindex, nofollow' />
        <meta
          name='keywords'
          content='sundrop solar , sundrop solar australia reviews , sundrop solar australia , sundrop solar reviews , solar installers melton , solar installers melbourne , solar installation tarneit , solar installers hoppers crossing , solar installers aintree , solar installers near me'
        />
        <link rel='canonical' href='https://sundropsolar.com.au/thank-you' />
      </MetaTags>
      <section id='breadcrumbs' class='breadcrumbs'>
        <div class='container'>
          <h1>Thank You</h1>
          <ul>
            <li>
              <a href='./'>Home</a>
            </li>
            <li>Thank You</li>
          </ul>
        </div>
      </section>
      <section className='entry'>
        <Container>
          <h2 className='text-center'>Thank You For Your Message..!!</h2>
          <p className='text-center'>
            Message Sent Successfully! We have recieved your mail and we will
            contact you shortly.
          </p>
          <p className='text-center'>
            Click to go on <a href='/'>Homepage</a>
          </p>
        </Container>
      </section>
    </div>
  )
}

export default ThankYou
