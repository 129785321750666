import { Col, Container, Row } from 'react-bootstrap'

import Brands from '../components/Brands'
import Discover from '../components/Discover'
import Ecosystem from '../assets/images/ecosystem.svg'
import MetaTags from 'react-meta-tags'
import React from 'react'
import Revolution from '../assets/images/solar-revolution.png'
import ScrollAnimation from 'react-animate-on-scroll'

const AboutUs = () => {
  return (
    <div>
      <MetaTags>
        <title>
          About Us | Sundrop Solar | Solar Panel Retailer and Installer
        </title>
        <meta
          name='description'
          content='One of the leading solar and energy providers in Australia. Specialize in roof mount solar system solutions, having completed more than 10 years of work experience.'
        />
        <meta
          name='keywords'
          content='sundrop solar , sundrop solar australia reviews , sundrop solar australia , sundrop solar reviews , solar installers melton , solar installers melbourne , solar installation tarneit , solar installers hoppers crossing , solar installers aintree , solar installers near me'
        />

        <link rel='canonical' href='https://sundropsolar.com.au/about-us' />
      </MetaTags>
      <section id='breadcrumbs' class='breadcrumbs'>
        <div class='container'>
          <h1>About Us</h1>
          <ul>
            <li>
              <a href='./'>Home</a>
            </li>
            <li>About Us</li>
          </ul>
        </div>
      </section>
      <section id='revolution'>
        <Container>
          <ScrollAnimation animateIn='fadeInUp'>
            <h2 className='text-center'>
              Leading The <span>Solar Revolution</span> Since 2012{' '}
            </h2>
          </ScrollAnimation>
          <Row>
            <Col xs={6} md={6} className='text-end'>
              <img src={Revolution} alt='Why Choose Us' className='img-fluid' />
            </Col>
            <Col xs={6} md={6} className='bg-2 my-auto'>
              <img src={Ecosystem} alt='Ecosystem' className='img-fluid' />
            </Col>
          </Row>
          <Row className='justify-content-md-center'>
            <Col xs={10} md={6} className='content'>
              <ScrollAnimation animateIn='fadeInUp'>
                <p>
                  We are one of the leading{' '}
                  <b>solar and energy providers in Australia</b> based in
                  Victoria. We specialize in roof mount solar system solutions,
                  having completed more than <b>10 years</b> of work experience
                  as a solar installer. Our highly experience team is here to
                  give you right advice about any products and services you
                  after.
                </p>
              </ScrollAnimation>
              <ScrollAnimation animateIn='fadeInUp'>
                <p>
                  Our highly skilled and experience staff are best at providing
                  detailed knowledge and solutions which helps you make you to
                  decide which product suits you the best.
                </p>
              </ScrollAnimation>
              <ScrollAnimation animateIn='fadeInUp'>
                <p>
                  We thrive to give you{' '}
                  <b>
                    100% satisfaction guarantee with no tolerance towards poor
                    workmanship.
                  </b>{' '}
                  We work in small team to support local community first where
                  you will quick response towards any fault or repair. We are
                  one of the few in the industry who are electricians and
                  technician who works on the field to install the solar system
                  and give you quick service after installation.
                </p>
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </section>
      <Discover />
      <div className='cmt-1'>
        <Brands />
      </div>
    </div>
  )
}

export default AboutUs
