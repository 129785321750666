import 'bootstrap/dist/css/bootstrap.min.css'
import './assets/css/style.css'
import './assets/css/responsive.css'

import App from './App'
import React from 'react'
import ReactDOM from 'react-dom'
import { getAnalytics } from 'firebase/analytics'
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app'
import reportWebVitals from './reportWebVitals'

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDX5FGdE9v6HJ4UzFgyA6cs7rs2hm6U3vk',
  authDomain: 'sundropsolarapp.firebaseapp.com',
  projectId: 'sundropsolarapp',
  storageBucket: 'sundropsolarapp.appspot.com',
  messagingSenderId: '365583833899',
  appId: '1:365583833899:web:9f93cd24d296123baac36e',
  measurementId: 'G-D7S6N34HGB',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
