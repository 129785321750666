import React, { useState } from 'react'
// Library
import axios from 'axios'
import { FORM_URL } from '../config'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import isEmail from 'validator/lib/isEmail'
import { useHistory } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider1 from '../assets/images/slider/slider-1.png'
import Slider2 from '../assets/images/slider/slider-2.png'
import { Alert, Container, Col, Row, Form } from 'react-bootstrap'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { LinearProgress } from '@mui/material'

const SliderArea = () => {
    var settings = {
        dots: true,
        infinite: true,
        arrows: false,
        speed: 500,
        slidesToShow: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        pauseOnHover: true
    };
    let history = useHistory();
    const [disabled, setDisabled] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [name, setName] = useState(null)
    const [email, setEmail] = useState(null)
    const [number, setNumber] = useState(null)
    const [address, setAddress] = useState(null)
    const [purpose, setPurpose] = useState(null)

    const progress = loading ? (<LinearProgress />) : ('')

    const mailError = (<p>{error}</p>)

    const handleName = (e) => {
        const value = e.target.value
        setName(value)
        setError(null)
    }
    const handleEmail = (e) => {
        var value = e.target.value
        value = ltrim(rtrim(value))
        setEmail(value)
        if (!isEmail(value)) {
            setError(<Alert variant={'danger'}>Please enter a valid email</Alert>)
            setDisabled(true)
        }
        else {
            setError(null)
            setDisabled(false)
        }
    }
    const handleMobile = (value) => {
        setNumber(value)
        setError(null)
    }
    const handleAddress = (e, value) => {
        setAddress(e.target.value)
        setError(null)
    }
    const handlePurpose = (e) => {
        const value = e.target.value
        setPurpose(value)
        setError(null)
    }

    const handleFormSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        if (name === null) {
            setError(<Alert variant={'danger'}>Your Name Required</Alert>)
            setLoading(false)
        } else if (number === null) {
            setError(<Alert variant={'danger'}>Mobile Number Required</Alert>)
            setLoading(false)
        } else if (address === null) {
            setError(<Alert variant={'danger'}>Address Required</Alert>)
            setLoading(false)
        } else if (purpose === null) {
            setError(<Alert variant={'danger'}>Purpose Required</Alert>)
            setLoading(false)
        } else {
            setDisabled(true)
            const data = {
                name: ltrim(rtrim(name)),
                email: (email === null || email === '') ? '' : ltrim(rtrim(email)),
                number: ltrim(rtrim(number)),
                address: ltrim(rtrim(address)),
                purpose: ltrim(rtrim(purpose))
            }
            const url = FORM_URL + '/callback'
            axios.post(url, data).then(response => {
                document.getElementById('callback').reset()
                setDisabled(false)
                setLoading(false)
                history.push('/thank-you')
            }).catch(error => {
                if (error.response) {
                    console.log(error.response)
                    setError(<Alert variant={'danger'}>Something went wront. Please try again later</Alert>)
                } else if (error.request) {
                    console.log(error.request)
                    setError(<Alert variant={'danger'}>Something went wront. Please try again later</Alert>)
                } else {
                    console.log(error);
                    setError(<Alert variant={'danger'}>Something went wront. Please try again later</Alert>)
                }
                setDisabled(false)
                setLoading(false)
            })
        }
    }
    return (
        <div id="slider">
            <Slider {...settings}>
                {/* eslint-disable-next-line */}
                <a href="https://www.solar.vic.gov.au/solar-panel-rebate" target="_blank">
                    <img src={Slider2} alt="Sundrop Solar Panel Retailer and Installer" className="img-fluid" />
                </a>
                <div className="slider-1">
                    <img src={Slider1} alt="Convert light to energy" className="img-fluid" />
                    <div className="centered">
                        <Container className="content">
                            <Row>
                                <Col md={6} className="my-auto">
                                    <h1>Convert <span className="type-1">Light</span> <br /> to <span className="type-2">Energy</span></h1>
                                    <p>Reduce Your Electricity Bill Today</p>
                                </Col>
                                <Col md={6}>
                                    {progress}
                                    <form className="form-slider d-none d-sm-block" id="callback" onSubmit={handleFormSubmit}>
                                        <h3>Request Callback</h3>
                                        <Row className="justify-content-md-center">
                                            <Col md={6}>
                                                <Form.Group>
                                                    <Form.Control type="text" placeholder="Your Name*" required inputProps={{ minLength: 3, maxLength: 100 }} onChange={handleName} />
                                                </Form.Group>
                                            </Col>
                                            <Col md={6}>
                                                <Form.Group className="mt-4">
                                                    <PhoneInput country={'au'} onChange={handleMobile} inputProps={{ minLength: 3, maxLength: 18, required: true }} />
                                                </Form.Group>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Control type="email" placeholder="Email" inputProps={{ maxLength: 100 }} onChange={handleEmail} />
                                                </Form.Group>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Control type="text" placeholder="Address*" required inputProps={{ minLength: 2, maxLength: 200 }} onChange={handleAddress} />
                                                </Form.Group>
                                            </Col>
                                            <Col md={12}>
                                                <Form.Control as="textarea" rows={4} placeholder="Purpose of Contact*" required inputProps={{ minLength: 3, maxLength: 200 }} onChange={handlePurpose} />
                                            </Col>
                                            {mailError}
                                            <Col md={5}>
                                                <button className="w-100" disabled={disabled}>SEND MESSAGE</button>
                                            </Col>
                                        </Row>
                                    </form>
                                    {progress}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            </Slider>
        </div>
    )
}

export default SliderArea
