import React from 'react'
import Brand1 from '../assets/images/brands/rgingpv.png'
import Brand2 from '../assets/images/brands/canadian-solar.png'
import Brand3 from '../assets/images/brands/solaredge.png'
import Brand4 from '../assets/images/brands/qcells.png'
import Brand5 from '../assets/images/brands/jinko.png'
import Brand6 from '../assets/images/brands/longisolar.png'
import Brand7 from '../assets/images/brands/trinasolar.png'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScrollAnimation from 'react-animate-on-scroll';

const Brands = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 7,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <section id="brands">
            <ScrollAnimation animateIn="fadeInUp">
                <div className="text-center">
                    <h2>Solar Plate <span>Brand We Install</span></h2>
                </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp">
                <Slider {...settings}>
                    <img src={Brand1} alt="rgingpv logo" className="img-fluid" />
                    <img src={Brand2} alt="canadian solar logo" className="img-fluid" />
                    <img src={Brand3} alt="solaredge logo" className="img-fluid" />
                    <img src={Brand4} alt="qcells logo" className="img-fluid" />
                    <img src={Brand5} alt="jinko logo" className="img-fluid" />
                    <img src={Brand6} alt="longisolar logo" className="img-fluid" />
                    <img src={Brand7} alt="trinasolar logo" className="img-fluid" />
                </Slider>
            </ScrollAnimation>
        </section>
    )
}

export default Brands
