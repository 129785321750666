import { Container } from 'react-bootstrap'
import MetaTags from 'react-meta-tags'
import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'

const PrivacyPolicy = () => {
  return (
    <div>
      <MetaTags>
        <title>Privacy Policy | Sundrop Solar</title>
        <meta name='description' content='Privacy Policy Page' />
        <meta
          name='keywords'
          content='sundrop solar , sundrop solar australia reviews , sundrop solar australia , sundrop solar reviews , solar installers melton , solar installers melbourne , solar installation tarneit , solar installers hoppers crossing , solar installers aintree , solar installers near me'
        />
        <link
          rel='canonical'
          href='https://sundropsolar.com.au/privacy-policy'
        />
      </MetaTags>
      <section id='breadcrumbs' class='breadcrumbs'>
        <div class='container'>
          <h1>Privacy Policy</h1>
          <ul>
            <li>
              <a href='./'>Home</a>
            </li>
            <li>Privacy Policy</li>
          </ul>
        </div>
      </section>
      <section className='policy'>
        <Container>
          <ScrollAnimation animateIn='fadeInUp'>
            <h2 className='type-3'>We collect information</h2>
            <p>
              We use technologies like cookies (small files stored by your
              browser), web beacons, or unique device identifiers to anonymously
              identify your computer or device so we can deliver a better
              experience. Our systems also log information like your browser,
              operating system and IP address.
            </p>
            <p>
              We also collect personally identifiable information about you. In
              other words, we also collect information such as your name,
              address, phone number or email address.
            </p>
            <p>
              We also knowingly contact or collect personal information from
              children under 13. If you believe we have inadvertently collected
              such information, please contact us so we can promptly obtain
              parental consent or remove the information.
            </p>
            <p>
              Our service does not currently recognize the “Do Not Track” signal
              that may be available in some web browsers.
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp'>
            <h2 className='type-3'>
              How we collect Personally identifiable information
            </h2>
            <p>
              When you register we may collect a variety of information,
              including your name, mailing address, email address.
            </p>
            <p>
              We may use cookies to identify that you’ve logged in to the
              Services. Although most browsers automatically accept cookies, you
              can change your browser options to stop automatically accepting
              cookies or to prompt you before accepting cookies. Please note,
              however, that if you don’t accept cookies, you will not be able to
              stay automatically logged in to the Services.
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp'>
            <h2 className='type-3'>
              How we use your Personally identifiable information
            </h2>
            <p>
              The personal information we collect allows us to keep you posted
              on Sundrop Solar latest Services and updates. Sundrop Solar and
              its affiliates may share personal information with each other and
              use it consistent with this Privacy Policy.They may also combine
              it with other information to provide and improve our Services.
            </p>
            <p>
              We also use personal information to help us develop, deliver, and
              improve our Services.From time to time, we may use your Personally
              identifiable information to send important notices, such as
              communications about changes to our terms, conditions, and
              policies.If you don’t want to be on our mailing list, you can opt
              out anytime by unregistering at{' '}
              <a href='mailto:info@sundropsolar.com.au'>
                info@sundropsolar.com.au
              </a>{' '}
              We may also use Personally identifiable information for internal
              purposes such as auditing, data analysis, and research to improve
              Sundrop Solar Services and customer communications.
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp'>
            <h2 className='type-3'>
              We may collect and share anonymous location data.
            </h2>
            <p>
              To customize our service for you, we and our partners may collect,
              use, and share precise location data, including the real-time
              geographic location of your computer or device.This location data
              is collected anonymously in a form that does not personally
              identify you and is used only to provide and improve our
              service.We may obtain your consent on your first use of the
              service.
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp'>
            <h2 className='type-3'>
              You can request to see your personal data.
            </h2>
            <p>
              We do not permit direct association with any specific individual.
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp'>
            <h2 className='type-3'>
              We keep personal data for a limited period.
            </h2>
            <p>
              We do store any of your personal information on our server also
              But Google Game service can collect your personal in for that
              please read google play game service privacy policy.
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp'>
            <h2 className='type-3'>
              We don’t share your personal data with other companies.
            </h2>
            <p>We do not store any of your personal information</p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp'>
            <h2 className='type-3'>
              Ad companies collect anonymous data.You can opt out.
            </h2>
            <p>
              Ad companies may use and collect anonymous data about your
              interests to customize content and advertising here and in other
              sites and applications.Interest and location data may be linked to
              your device, but is not linked to your identity.Click to see
              company privacy policies and opt-out choices:{' '}
            </p>
            <p>
              Google Ads (Admeld, Admob, Channel Intelligence, DoubleClick,
              Display Network, Teracent)
            </p>
            <p>
              Admeld Admeld pioneered the private ad exchange and built
              technology that made it easy for publishers to identify new
              opportunities and control how every impression is sold.Admob AdMob
              has been rebuilt, leveraging the best of Google’s ad
              platforms.Channel Intelligence Channel intelligence optimizes
              product visibility on shopping engines.With our bidding expertise,
              data optimization technology, and data quality monitoring, we
              elevate performance on shopping engines.DoubleClick for Publishers
              (DFP) is a comprehensive hosted ad serving platform that
              streamlines ad management functions and allows generating
              even-greater advertising margins.Display Network The Google
              Display Network helps generate awareness by getting ads in front
              of consumers, so they can learn about the business as they
              consider their options.Teracent Teracent’s Intelligent Display
              Advertising creates display ads entirely customized to the
              specific consumer and site proposition for marketers that was,
              until now, cost and time prohibitive.
            </p>
            {/* eslint-disable-next-line */}
            <p>
              <a
                href='http://www.google.com/intl/en/policies/technologies/ads/'
                target='_blank'
              >
                Privacy Policy and Choices
              </a>
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp'>
            <h2 className='type-3'>You can ask privacy questions.</h2>
            <p>
              If you have any questions or concerns about our privacy policies,
              please contact{' '}
              <a href='mailto:info@sundropsolar.com.au'>
                info@sundropsolar.com.au
              </a>
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp'>
            <h2 className='type-3'>
              Service providers access data on our behalf.
            </h2>
            <p>
              In order to serve you, we may share your personal and anonymous
              information with other companies, including vendors and
              contractors.Their use of information is limited to these purposes,
              and subject to agreements that require them to keep the
              information confidential.Our vendors provide assurance that they
              take reasonable steps to safeguard the data they hold on our
              behalf, although data security cannot be guaranteed.
            </p>
            <p>
              Analytics companies may access anonymous data (such as your IP
              address or device ID) to help us understand how our services are
              used.They use this data solely on our behalf.They do not share it
              except in aggregate form; no data is shared as to any individual
              user.Click to see company privacy policies that govern their use
              of data.
            </p>
            <p>
              Google Ads (Admeld, Admob, Channel Intelligence, DoubleClick,
              Display Network, Teracent)
            </p>
            <p>
              Admeld Admeld pioneered the private ad exchange and built
              technology that made it easy for publishers to identify new
              opportunities and control how every impression is sold.Admob AdMob
              has been rebuilt, leveraging the best of Google’s ad
              platforms.Channel Intelligence Channel intelligence optimizes
              product visibility on shopping engines.With our bidding expertise,
              data optimization technology, and data quality monitoring, we
              elevate performance on shopping engines.DoubleClick for Publishers
              (DFP) is a comprehensive hosted ad serving platform that
              streamlines ad management functions and allows generating
              even-greater advertising margins.Display Network The Google
              Display Network helps generate awareness by getting ads in front
              of consumers, so they can learn about the business as they
              consider their options.Teracent Teracent’s Intelligent Display
              Advertising creates display ads entirely customized to the
              specific consumer and site proposition for marketers that was,
              until now, cost and time prohibitive.
            </p>
            {/* eslint-disable-next-line */}
            <p>
              <a
                href='http://www.google.com/intl/en/policies/technologies/ads/'
                target='_blank'
              >
                Privacy Policy and Choices
              </a>
            </p>
            <p>Google Analytics</p>
            <p>
              Google Analytics is a web analytics tool that helps website owners
              understand how visitors engage with their website.Google Analytics
              customers can view a variety of reports about how visitors
              interact with their website so they can improve it.Google
              Analytics collects information anonymously.It reports website
              trends without identifying individual visitors.
            </p>
            {/* eslint-disable-next-line */}
            <p>
              <a
                href='http://www.google.com/analytics/learn/privacy.html'
                target='_blank'
              >
                Privacy Policy and Choices
              </a>
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp'>
            <h2 className='type-3'>
              We take steps to protect personal information
            </h2>
            <p>We store any of your personal information</p>
            <p>
              Information we collect may be stored or processed on computers
              located in any country where we do business.
            </p>
            <p>
              We cannot guarantee to protect your information from hackers or
              human error.
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp'>
            <h2 className='type-3'>
              Special situations may require disclosure of your data.
            </h2>
            <p>
              To operate the service, we also may make identifiable and
              anonymous information available to third parties in these limited
              circumstances: (1) with your express consent, (2) when we have a
              good faith belief it is required by law, (3) when we have a good
              faith belief it is necessary to protect our rights or property, or
              (4) to any successor or purchaser in a merger, acquisition,
              liquidation, dissolution or sale of assets.Your consent will not
              be required for disclosure in these cases, but we will attempt to
              notify you, to the extent permitted by law to do so.
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp'>
            <h2 className='type-3'>
              You can review more privacy-related information.
            </h2>
            <p>
              This privacy policy was last updated on 5 Oct 2021. Our privacy
              policy may change from time to time.If we make any material
              changes to our policies, we will place a prominent notice on our
              website or application.If the change materially affects registered
              users, we will send a notice to you by email, push notification or
              text.
            </p>
            <p>
              Contact Us or email Us at{' '}
              <a href='mailto:info@sundropsolar.com.au'>
                info@sundropsolar.com.au
              </a>{' '}
              any query regarding privacy policy.
            </p>
            <p>© 2021 – Sundrop Solar</p>
          </ScrollAnimation>
        </Container>
      </section>
    </div>
  )
}

export default PrivacyPolicy
