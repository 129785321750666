import React from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SolarInstallation from '../assets/images/solar-installation.png'
import { Button, Col, Container, Row } from 'react-bootstrap'
import ScrollAnimation from 'react-animate-on-scroll';

const Discover = () => {
    return (
        <section id="solar">
            <Container>
                <Row>
                    <Col md={6} className="video">
                        <ScrollAnimation animateIn="fadeInUp">
                            <img src={SolarInstallation} alt="Why Choose Us" className="img-fluid img-main" />
                        </ScrollAnimation>
                    </Col>
                    <Col md={5} className="my-auto">
                        <ScrollAnimation animateIn="fadeInUp">
                            <h2>Discover Independence Through Using Power Of Smooth Solar Panels!</h2>
                            <a href="/#pricing"><Button className="btn-pricing">Pricing Plans <ArrowForwardIcon /></Button></a>
                        </ScrollAnimation>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default Discover
