import 'react-phone-input-2/lib/style.css'

import { Alert, Col, Container, Form, Row } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'

import AccessTimeIcon from '@mui/icons-material/AccessTime'
import EmailIcon from '@mui/icons-material/Email'
import { FORM_URL } from '../config'
import { LinearProgress } from '@mui/material'
import MetaTags from 'react-meta-tags'
import PhoneIcon from '@mui/icons-material/Phone'
import PhoneInput from 'react-phone-input-2'
import RoomIcon from '@mui/icons-material/Room'
import ScrollAnimation from 'react-animate-on-scroll'
import TextField from '@mui/material/TextField'
import axios from 'axios'
import isEmail from 'validator/lib/isEmail'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import { useHistory } from 'react-router-dom'

const ContactUs = () => {
  const to_email = 'info@sundropsolar.com.au'
  const cc = 'arkayappsseo@gmail.com'
  const title = 'Website Inquiry'
  const subject = 'Contact Form - Sundrop Solar'
  let history = useHistory()
  useEffect(() => {
    window.scrollTo(0, 0)
    // eslint-disable-next-line
  }, [])
  const [disabled, setDisabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [name, setName] = useState(null)
  const [email, setEmail] = useState(null)
  const [number, setNumber] = useState(null)
  const [message, setMessage] = useState(null)
  const template = `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta
          name='keywords'
          content='sundrop solar , sundrop solar australia reviews , sundrop solar australia , sundrop solar reviews , solar installers melton , solar installers melbourne , solar installation tarneit , solar installers hoppers crossing , solar installers aintree , solar installers near me'
        />
        <title>Sundrop Solar</title>
        <style>
            body {
                line-height: 1.4;
                font-family: sans-serif;
                background-color: #f6f6f6;
            }
            p {
                margin: 0;
                margin-bottom: 15px;
                font-size: 14px;
                font-weight: normal;
                font-family: sans-serif;
            }
            hr {
                border: 0;
                border-bottom: 1px solid #b9b9b9;
                margin: 10px 0;
            }
            .logo {
                margin-top: 1rem;
                margin-bottom: 1rem;
                width:180px;
              
            }
            .text-center {
                text-align: center !important;
            }
            .content {
                Margin: 0 auto;
                display: block;
                max-width: 580px;
                padding: 0 10px 0 10px;
                box-sizing: border-box;
            }
            .innerwrap {
                box-sizing: border-box;
                padding: 20px 20px 10px 20px;
                background: #f2f2f2;
            }
            .card-header {
                background:#0061C3;
                color: #fff;
                text-align: center;
                padding: 20px;
                font-size: 16px;
            }
            .card-header p {
                color: #fff;
                text-align: center;
                font-size: 16px;
                margin-bottom: unset;
            }
            .card-body {
                background: #ffffff;
                border-radius: 3px;
                width: 100%;
                box-sizing: border-box;
                padding: 20px;
                border-bottom: 3px solid #0061C3;
            }
            .card-body p strong {
                color: #333;
            }
            .card-body p strong.heading {
                color:#2A2765;
            }
            .copyright {
                color: #999999;
                font-size: 12px;
                text-align: center;
                margin-top: 1rem;
                margin-bottom: 1rem;
            }
        </style>
    </head>
    <body>
        <div class="content">
            <div class="text-center">
                <img src="https://sundropsolar.com.au/sundropsolar-logo.png" alt="logo" class="logo">
            </div>
            <div class="card-header">
                <img src="https://arkayapps.s3.ap-south-1.amazonaws.com/assets/email.png">
                <p>You Received an Inquiry !</p>
            </div>
            <div class="card-body">
                <p><strong>Name : </strong>${name}</p>
                <p><strong>Message : </strong>${message}</p>
                <hr>
                <p><strong class="heading">Contact Information :</strong></p>
                <div class="innerwrap">
                    <p><strong>Mobile No: </strong>${number}</p>
                    <p><strong>Email: </strong>${email}</p>
                </div>
            </div>
            <div class="copyright">
                <p>&copy; 2023 Sundrop Solar</p>
            </div>
        </div>
    </body>
    </html>`

  const progress = loading ? <LinearProgress /> : ''

  const mailError = <p>{error}</p>

  const handleName = (e) => {
    const value = e.target.value
    setName(value)
    setError(null)
  }
  const handleEmail = (e) => {
    var value = e.target.value
    value = ltrim(rtrim(value))
    setEmail(value)
    if (!isEmail(value)) {
      setError(<Alert variant={'danger'}>Please enter a valid email</Alert>)
      setDisabled(true)
    } else {
      setError(null)
      setDisabled(false)
    }
  }
  const handleMobile = (value) => {
    setNumber(value)
    setError(null)
  }
  const handleMessage = (e) => {
    const value = e.target.value
    setMessage(value)
    setError(null)
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    if (name === null) {
      setError(<Alert variant={'danger'}>Full Name Required</Alert>)
      setLoading(false)
    } else if (number === null) {
      setError(<Alert variant={'danger'}>Contact Number Required</Alert>)
      setLoading(false)
    } else if (message === null) {
      setError(<Alert variant={'danger'}>Message Required</Alert>)
      setLoading(false)
    } else {
      setDisabled(true)
      const data = {
        title: title,
        to_email: to_email,
        cc: cc,
        template: template,
        subject: subject,
      }
      const headerConfig = {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
      const url = 'https://api.sendmail.adhyaynam.in/send-mail'
      axios
        .post(url, data, headerConfig)
        .then((response) => {
          document.getElementById('contact-us').reset()
          setDisabled(false)
          setLoading(false)
          history.push('/thank-you')
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response)
            setError(
              <Alert variant={'danger'}>
                Something went wront. Please try again later
              </Alert>
            )
          } else if (error.request) {
            console.log(error.request)
            setError(
              <Alert variant={'danger'}>
                Something went wront. Please try again later
              </Alert>
            )
          } else {
            console.log(error)
            setError(
              <Alert variant={'danger'}>
                Something went wront. Please try again later
              </Alert>
            )
          }
          setDisabled(false)
          setLoading(false)
        })
    }
  }
  return (
    <div>
      <MetaTags>
        <title>
          Contact Us | Sundrop Solar | Solar Panel Retailer and Installer
        </title>
        <meta
          name='description'
          content='One of the leading solar and energy providers in Australia. Specialize in roof mount solar system solutions, having completed more than 10 years of work experience.'
        />
        <link rel='canonical' href='https://sundropsolar.com.au/contact-us' />
      </MetaTags>
      <section id='breadcrumbs' class='breadcrumbs'>
        <div class='container'>
          <h1>Contact Us</h1>
          <ul>
            <li>
              <a href='./'>Home</a>
            </li>
            <li>Contact Us</li>
          </ul>
        </div>
      </section>
      <section>
        <Container>
          <Row>
            <Col md={3} xs={11} className='contact-info pb-3'>
              <ScrollAnimation animateIn='fadeInUp'>
                <div className='icon'>
                  <RoomIcon />
                </div>
                <h4>Address</h4>
                <p>
                  8 Fegent Road <br /> Tarneit, VIC 3029 <br />
                  Australia
                </p>
                <div className='icon'>
                  <EmailIcon />
                </div>
                <h4>Email</h4>
                <p>
                  <a href='mailto:info@sundropsolar.com.au'>
                    info@sundropsolar.com.au
                  </a>
                </p>
                <div className='icon'>
                  <PhoneIcon />
                </div>
                <h4>Phone</h4>
                <p>
                  <a href='tel:+61 41 35 28481'>+61 41 35 28481</a>
                </p>
                <div className='icon'>
                  <AccessTimeIcon />
                </div>
                <h4>Opening Hours</h4>
                <p>Mon – Fri 9:00 – 5:00</p>
              </ScrollAnimation>
            </Col>
            <Col md={9} xs={12}>
              {progress}
              <ScrollAnimation animateIn='fadeInUp'>
                <Form
                  className='form'
                  id='contact-us'
                  onSubmit={handleFormSubmit}
                >
                  <div className='text-center'>
                    <h2>
                      <span>Get in Touch</span> and let us know how we can help
                    </h2>
                  </div>
                  <Row className='justify-content-md-center'>
                    <Col md={12}>
                      <TextField
                        className='form-control'
                        label='Full Name'
                        size='small'
                        variant='outlined'
                        required
                        inputProps={{ minLength: 3, maxLength: 50 }}
                        onChange={handleName}
                      />
                    </Col>
                    <Col md={6}>
                      <TextField
                        className='form-control'
                        label='Email'
                        type='email'
                        size='small'
                        variant='outlined'
                        inputProps={{ minLength: 3, maxLength: 100 }}
                        onChange={handleEmail}
                      />
                    </Col>
                    <Col md={6}>
                      <PhoneInput
                        country={'au'}
                        onChange={handleMobile}
                        inputProps={{
                          minLength: 3,
                          maxLength: 18,
                          required: true,
                        }}
                      />
                    </Col>
                    <Col md={12}>
                      <TextField
                        multiline
                        rows={4}
                        className='form-control'
                        size='small'
                        label='Message'
                        variant='outlined'
                        required
                        inputProps={{ minLength: 3, maxLength: 200 }}
                        onChange={handleMessage}
                      />
                    </Col>
                    {mailError}
                    <Col md={4}>
                      <button
                        className='w-100'
                        type='submit'
                        disabled={disabled}
                      >
                        SEND MESSAGE
                      </button>
                    </Col>
                  </Row>
                </Form>
              </ScrollAnimation>
              {progress}
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default ContactUs
