import React from 'react'
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap'
import Kw5 from '../assets/images/5kw.svg'
import Kw6 from '../assets/images/6-6kw.svg'
import CheckIcon from '@mui/icons-material/Check';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ScrollAnimation from 'react-animate-on-scroll';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const PricingBattery = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <section id="pricing">
            <Container>
                <ScrollAnimation animateIn="fadeInUp">
                    <div className="text-center">
                        <h2 className="type-2">Solar Battery</h2>
                    </div>
                </ScrollAnimation>
                <Box sx={{ width: '100%' }}>
                    <ScrollAnimation animateIn="fadeInUp">
                        <Box>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                                <Tab label="Battery Ready" {...a11yProps(0)} />
                                <Tab label="Hybrid" {...a11yProps(1)} />
                                <Tab label="Retrofit" {...a11yProps(2)} />
                            </Tabs>
                        </Box>
                    </ScrollAnimation>
                    <TabPanel value={value} index={0}>
                        <Row className="justify-content-md-center">
                            <Col md={3} className="my-auto">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div className="pricing">
                                        <div className="header type-1">
                                            <img src={Kw5} alt="Kw5" />
                                            <hr />
                                            <h4>5kW</h4>
                                        </div>
                                        <div className="body">
                                            <ul>
                                                <li><CheckIcon />Medium Home</li>
                                                <li><CheckIcon />14 x 370 = 5180 W Tier 1 Panels</li>
                                                <li><CheckIcon />Upto 25 Year Linear Power Warranty</li>
                                                <li><CheckIcon />3kW Hybrid Battery Inverter</li>
                                            </ul>
                                        </div>
                                        <div className="footer">
                                            <Link to="/contact-us"><ArrowForwardIcon /></Link>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            </Col>
                            <Col md={3} className="my-auto">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div className="pricing">
                                        <div className="header type-2">
                                            <img src={Kw6} alt="Kw6" />
                                            <hr />
                                            <h4>6.6kW</h4>
                                        </div>
                                        <div className="body">
                                            <ul>
                                                <li><CheckIcon />Standard Home</li>
                                                <li><CheckIcon />17 x 390 = 6630 W Tier 1 Panels</li>
                                                <li><CheckIcon />Upto 25 Year Linear Power Warranty</li>
                                                <li><CheckIcon />5kW Hybrid Battery Inverter</li>
                                            </ul>
                                        </div>
                                        <div className="footer">
                                            <Link to="/contact-us"><ArrowForwardIcon /></Link>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            </Col>
                        </Row>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Row className="justify-content-md-center">
                            <Col md={3} className="my-auto">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div className="pricing">
                                        <div className="header type-1">
                                            <img src={Kw5} alt="Kw5" />
                                            <hr />
                                            <h4>5kW</h4>
                                        </div>
                                        <div className="body">
                                            <ul>
                                                <li><CheckIcon />Medium Battery Storage</li>
                                                <li><CheckIcon />14 x 370 = 5180 W Tier 1 Panels</li>
                                                <li><CheckIcon />Upto 25 Year Linear Power Warranty</li>
                                                <li><CheckIcon />3kW Hybrid Battery Inverter</li>
                                                <li><CheckIcon />Available options: 3.5kWh to 13.5kWh</li>
                                            </ul>
                                        </div>
                                        <div className="footer">
                                            <Link to="/contact-us"><ArrowForwardIcon /></Link>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            </Col>
                            <Col md={3} className="my-auto">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div className="pricing">
                                        <div className="header type-2">
                                            <img src={Kw6} alt="Kw6" />
                                            <hr />
                                            <h4>6.6kW</h4>
                                        </div>
                                        <div className="body">
                                            <ul>
                                                <li><CheckIcon />Large Battery Storage</li>
                                                <li><CheckIcon />17 x 390 = 6630 W Tier 1 Panels</li>
                                                <li><CheckIcon />Upto 25 Year Linear Power Warranty</li>
                                                <li><CheckIcon />5kW Hybrid Battery Inverter</li>
                                                <li><CheckIcon />Available options: 6kWh to 20kWh</li>
                                            </ul>
                                        </div>
                                        <div className="footer">
                                            <Link to="/contact-us"><ArrowForwardIcon /></Link>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            </Col>
                        </Row>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <Row className="justify-content-md-center">
                            <Col md={3} className="my-auto">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div className="pricing">
                                        <div className="header type-1">
                                            <img src={Kw5} alt="Kw5" />
                                            <hr />
                                            <h4>3kW</h4>
                                        </div>
                                        <div className="body">
                                            <ul>
                                                <li><CheckIcon />Battery Storage</li>
                                                <li><CheckIcon />Inbuilt 3kW Inverter</li>
                                                <li><CheckIcon />Upto 10 Years Product Warranty</li>
                                                <li><CheckIcon />Backup power (optional)</li>
                                                <li><CheckIcon />WiFi Monitoring</li>
                                                <li><CheckIcon />Available options: 2.99kWh to 16.5kWh</li>
                                            </ul>
                                        </div>
                                        <div className="footer">
                                            <Link to="/contact-us"><ArrowForwardIcon /></Link>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            </Col>
                            <Col md={3} className="my-auto">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div className="pricing">
                                        <div className="header type-2">
                                            <img src={Kw6} alt="Kw6" />
                                            <hr />
                                            <h4>5kW</h4>
                                        </div>
                                        <div className="body">
                                            <ul>
                                                <li><CheckIcon />Battery Storage</li>
                                                <li><CheckIcon />Inbuilt 5kW Inverter</li>
                                                <li><CheckIcon />Upto 10 Years Product Warranty</li>
                                                <li><CheckIcon />Backup power (optional)</li>
                                                <li><CheckIcon />WiFi Monitoring</li>
                                                <li><CheckIcon />Available options: 2.99kWh to 18.66kWh</li>
                                            </ul>
                                        </div>
                                        <div className="footer">
                                            <Link to="/contact-us"><ArrowForwardIcon /></Link>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            </Col>
                        </Row>
                    </TabPanel>
                </Box>
            </Container>
        </section>
    )
}

export default PricingBattery
