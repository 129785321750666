import React from 'react'
import Battery1 from '../assets/images/battery/senec.png'
import Battery2 from '../assets/images/battery/sungrow.png'
import Battery3 from '../assets/images/battery/goodwe.png'
import Battery4 from '../assets/images/battery/hresys.png'
import Battery5 from '../assets/images/battery/byd.png'
import Battery6 from '../assets/images/battery/spectronic.png'
import Battery7 from '../assets/images/battery/alpha.png'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScrollAnimation from 'react-animate-on-scroll';

const Battery = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 7,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <section id="brands">
            <ScrollAnimation animateIn="fadeInUp">
                <div className="text-center">
                    <h2>Solar Battery <span>Brand We Install</span></h2>
                </div>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp">
                <Slider {...settings}>
                    <img src={Battery1} alt="Senec Battery" className="img-fluid" />
                    <img src={Battery2} alt="Sungrow Battery" className="img-fluid" />
                    <img src={Battery3} alt="Goodwe Battery" className="img-fluid" />
                    <img src={Battery4} alt="Hresys Battery" className="img-fluid" />
                    <img src={Battery5} alt="BYD Battery" className="img-fluid" />
                    <img src={Battery6} alt="Spectronic Battery" className="img-fluid" />
                    <img src={Battery7} alt="Alpha Battery" className="img-fluid" />
                </Slider>
            </ScrollAnimation>
        </section>
    )
}

export default Battery
