export const menu = [
    {
        label: 'Home',
        path: '/'
    },
    {
        label: 'About Us',
        path: '/about-us'
    },
    {
        label: 'Solar PV Packages',
        path: '/solar-pv-packages'
    },
    {
        label: 'Solar Battery Storage',
        path: '/solar-battery-storage'
    },
    {
        label: 'Contact',
        path: '/contact-us'
    },
    {
        label: 'Support',
        path: '/support'
    }
]
