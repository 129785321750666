import { Col, Container, Row } from 'react-bootstrap'

import EmailIcon from '@mui/icons-material/Email'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import Logo from '../assets/images/logo/logo-footer.png'
import PhoneIcon from '@mui/icons-material/Phone'
import React from 'react'
import RoomIcon from '@mui/icons-material/Room'

const Footer = () => {
  return (
    <div id='footer'>
      <section>
        <Container>
          <Row>
            <Col md={3}>
              <div className='text-center'>
                <img src={Logo} alt='Sundrop Solar logo' className='mb-4' />
              </div>
              <p>
                We are one of the leading solar and energy providers in
                Australia based in Victoria. We specialize in roof mount solar
                system solutions.
              </p>
              <div className='social-media'>
                {/* eslint-disable-next-line */}
                <a
                  href='https://www.facebook.com/SunDrop-Solar-105291581923848'
                  target='_blank'
                >
                  <FacebookIcon />
                </a>
                {/* eslint-disable-next-line */}
                <a
                  href='https://www.linkedin.com/in/sundrop-solar-467b1b222/'
                  target='_blank'
                >
                  <LinkedInIcon />
                </a>
                {/* eslint-disable-next-line */}
                <a
                  href='https://www.instagram.com/Sundropsolar139/'
                  target='_blank'
                >
                  <InstagramIcon />
                </a>
              </div>
            </Col>
            <Col md={2}>
              <h4>Quick Links</h4>
              <ul>
                <li>
                  <a href='/'>Home</a>
                </li>
                <li>
                  <a href='/about-us'>About Us</a>
                </li>
                <li>
                  <a href='/solar-pv-packages'>Solar Pv Packages</a>
                </li>
                <li>
                  <a href='/solar-battery-storage'>Solar Battery Storage</a>
                </li>
                <li>
                  <a href='/contact-us'>Contact Us</a>
                </li>
              </ul>
            </Col>
            <Col md={3} className='contact'>
              <h4>Contact Us</h4>
              <RoomIcon />
              <p>
                8 Fegent Road <br /> Tarneit, VIC 3029 <br /> Australia
              </p>
              <PhoneIcon />
              <p>
                <a href='tel:+61 41 35 28481'>+61 41 35 28481</a>
              </p>
              <EmailIcon />
              <p>
                <a href='mailto:info@sundropsolar.com.au'>
                  info@sundropsolar.com.au
                </a>
              </p>
            </Col>
            <Col md={4}>
              <h4>Google Maps</h4>
              <iframe
                src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1873.1626485684208!2d144.66951606527758!3d-37.85393700337475!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad68faa44e0a6df%3A0x60da835b13e4dcd7!2sSUNDROP%20SOLAR!5e0!3m2!1sen!2sin!4v1633154236169!5m2!1sen!2sin'
                width='100%'
                height='200'
                title='Google Maps Location'
                allowfullscreen=''
                loading='lazy'
              ></iframe>
            </Col>
          </Row>

          <hr />
          <Row className='copyright'>
            <Col md={7}>
              <p>
                &copy; 2020-{new Date().getFullYear().toString().substr(-2)}{' '}
                Sundrop Solar. All rights reserved.
              </p>
            </Col>
            <Col md={5} className='links'>
              <p>
                <a href='/privacy-policy'>Privacy-Policy</a>
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  )
}

export default Footer
