// Library
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom'

import AboutUs from './pages/AboutUs'
import ContactUs from './pages/ContactUs'
import Footer from './components/Footer'
// Components
import Header from './components/Header'
// Pages
import Homepage from './pages/Homepage'
import PrivacyPolicy from './pages/PrivacyPolicy'
// Core
import React from 'react'
import SolarBatteryStorage from './pages/SolarBatteryStorage'
import SolarPvPackages from './pages/SolarPvPackages'
import Support from './pages/Support'
import ThankYou from './pages/ThankYou'

function App() {
  return (
    <div>
      <Router>
        <Header />
        <Switch>
          <Route exact path='/' component={Homepage} />
          <Route exact path='/about-us' component={AboutUs} />
          <Route exact path='/solar-pv-packages' component={SolarPvPackages} />
          <Route
            exact
            path='/solar-battery-storage'
            component={SolarBatteryStorage}
          />
          <Route exact path='/contact-us' component={ContactUs} />
          <Route exact path='/support' component={Support} />
          <Route exact path='/privacy-policy' component={PrivacyPolicy} />
          <Route exact path='/thank-you' component={ThankYou} />
        </Switch>
        <Footer />
      </Router>
    </div>
  )
}

export default App
