import { Button, Col, Container, Row } from 'react-bootstrap'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Battery from '../components/Battery'
import Benefits from '../assets/images/benefits.svg'
import Clients from '../components/Clients'
import Contact from '../assets/images/contact.png'
import Discover from '../components/Discover'
import Electricity from '../assets/images/electricity.svg'
import Energy from '../assets/images/energy.svg'
import { Link } from 'react-router-dom'
import MetaTags from 'react-meta-tags'
import PricingBattery from '../components/PricingBattery'
import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'

const SolarBatteryStorage = () => {
  return (
    <div>
      <MetaTags>
        <title>
          Solar Battery Storage | Sundrop Solar | Solar Panel Retailer and
          Installer
        </title>
        <meta
          name='description'
          content='One of the leading solar and energy providers in Australia. Specialize in roof mount solar system solutions, having completed more than 10 years of work experience.'
        />
        <meta
          name='keywords'
          content='sundrop solar , sundrop solar australia reviews , sundrop solar australia , sundrop solar reviews , solar installers melton , solar installers melbourne , solar installation tarneit , solar installers hoppers crossing , solar installers aintree , solar installers near me'
        />
        <link
          rel='canonical'
          href='https://sundropsolar.com.au/solar-battery-storage'
        />
      </MetaTags>
      <section id='breadcrumbs' class='breadcrumbs'>
        <div class='container'>
          <h1>Solar Battery Storage</h1>
          <ul>
            <li>
              <a href='./'>Home</a>
            </li>
            <li>Solar Battery Storage</li>
          </ul>
        </div>
      </section>
      <section>
        <Container>
          <ScrollAnimation animateIn='fadeInUp'>
            <h2 className='text-center'>
              <span>Battery Storage</span> Is The <br /> Future Of Energy In The
              Home.
            </h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp'>
            <p className='text-center'>
              Whether You Want To Add Solar Battery Storage To Your Current
              Solar Power System Or Start Generating Power With A Complete Solar
              Power Battery Package, Solar Battery Storage Will Help You Achieve
              Your Goals Of Energy Independence.
            </p>
          </ScrollAnimation>
          <ScrollAnimation animateIn='fadeInUp'>
            <h2 className='type-3 cmt-1'>
              How Does Home <br />
              <span>Battery Storage</span> Work?
            </h2>
          </ScrollAnimation>
          <Row>
            <Col md={4}>
              <ScrollAnimation animateIn='fadeInUp'>
                <div className='work'>
                  <p className='number'>01</p>
                  <img src={Electricity} alt='how it works' />
                  <h3>Create and Store Electricity</h3>
                  <p>
                    Whether you want to add solar battery storage to your
                    current solar power system or start generating power with a
                    complete solar power battery package, solar battery storage
                    will help you achieve your goals of energy independence.
                  </p>
                </div>
              </ScrollAnimation>
            </Col>
            <Col md={4}>
              <ScrollAnimation animateIn='fadeInUp'>
                <div className='work'>
                  <p className='number'>02</p>
                  <img src={Energy} alt='how it works' />
                  <h3>Use Energy When You need It</h3>
                  <p>
                    You can start using your battery stored electricity when
                    your home’s energy needs become more than what your solar
                    panels can deliver on their own. this could be on cloudy
                    days, at night, or – most importantly – during a blackout.
                  </p>
                </div>
              </ScrollAnimation>
            </Col>
            <Col md={4}>
              <ScrollAnimation animateIn='fadeInUp'>
                <div className='work'>
                  <p className='number'>03</p>
                  <img src={Benefits} alt='how it works' />
                  <h3>Enjoy the benefits And Lower your Bills</h3>
                  <p>
                    Powering your home with solar panels and battery storage
                    delivers you benefits for years to come. you’ll be making
                    the most of your solar panels, maximising your energy
                    independence, and lowering your ongoing energy bills.
                  </p>
                </div>
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </section>
      <PricingBattery />
      <Clients />
      <ScrollAnimation animateIn='fadeInUp'>
        <section id='schedule'>
          <Container>
            <h2>Want To Know More ?</h2>
            <Link to='/contact-us'>
              <Button className='btn-pricing mt-1 float-start'>
                Schedule A Visit <ArrowForwardIcon />
              </Button>
            </Link>
            <div className='call'>
              <a href='tel:+61 41 35 28481'>
                <img src={Contact} alt='call' className='float-start' />
                <h3>
                  +61 41 35 28481
                  <span>
                    <br /> Call 24Hrs / 7 Days
                  </span>
                </h3>
              </a>
            </div>
          </Container>
        </section>
      </ScrollAnimation>
      <Discover />
      <div className='cmt-1'>
        <Battery />
      </div>
    </div>
  )
}

export default SolarBatteryStorage
