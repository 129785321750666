import { Col, Container, Row } from 'react-bootstrap/'

import Accreditation1 from '../assets/images/accreditation/netcc.png'
import Accreditation2 from '../assets/images/accreditation/saa.png'
import Accreditation3 from '../assets/images/accreditation/australian-owned.png'
import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll'

const Clients = () => {
  return (
    <section id='client'>
      <Container>
        <ScrollAnimation animateIn='fadeInUp'>
          <div className='text-center'>
            <h2>
              Our <span>Membership</span> and <span>Accreditation</span>
            </h2>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInUp'>
          <Row>
            <Col xs={12} md={4}>
              <img
                src={Accreditation1}
                alt='Clean Energy Council Accredited Installer'
                className='img-fluid client'
              />
            </Col>
            <Col xs={12} md={4}>
              <img
                src={Accreditation2}
                alt='Clean Energy Council Accredited Designer'
                className='img-fluid client'
              />
            </Col>
            <Col xs={12} md={4}>
              <img
                src={Accreditation3}
                alt='Clean Energy Council Approved Retailer'
                className='img-fluid client'
              />
            </Col>
          </Row>
        </ScrollAnimation>
      </Container>
    </section>
  )
}

export default Clients
