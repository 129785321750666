import React from 'react'
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap'
import Kw5 from '../assets/images/5kw.svg'
import Kw6 from '../assets/images/6-6kw.svg'
import Kw10 from '../assets/images/10kw.svg'
import Kw13 from '../assets/images/13-2kw.svg'
import CheckIcon from '@mui/icons-material/Check';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import ScrollAnimation from 'react-animate-on-scroll';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const PricingProducts = () => {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <section id="pricing">
            <Container>
                <ScrollAnimation animateIn="fadeInUp">
                    <div className="text-center">
                        <h2 className="type-2">Solar Products</h2>
                    </div>
                </ScrollAnimation>
                <Box sx={{ width: '100%' }}>
                    <Box>
                        <ScrollAnimation animateIn="fadeInUp">
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
                                <Tab label="Domestic" {...a11yProps(0)} />
                                <Tab label="Commercial" {...a11yProps(1)} />
                            </Tabs>
                        </ScrollAnimation>
                    </Box>
                    <TabPanel value={value} index={0}>
                        <Row className="justify-content-md-center">
                            <Col md={3} className="my-auto">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div className="pricing">
                                        <div className="header type-1">
                                            <img src={Kw5} alt="Kw5" />
                                            <hr />
                                            <h4>5kW</h4>
                                        </div>
                                        <div className="body">
                                            <ul>
                                                <li><CheckIcon />14 X 370 = 5180 W Tier 1 Panels</li>
                                                <li><CheckIcon />Upto 25 Years Solar Panel Warranty</li>
                                                <li><CheckIcon />3Kw Smart Inverter</li>
                                                <li><CheckIcon />Wifi Monitoring</li>
                                            </ul>
                                        </div>
                                        <div className="footer">
                                            <Link to="/contact-us"><ArrowForwardIcon /></Link>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            </Col>
                            <Col md={3} className="my-auto">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div className="pricing">
                                        <div className="header type-2">
                                            <img src={Kw6} alt="Kw6" />
                                            <hr />
                                            <h4>6.6kW</h4>
                                        </div>
                                        <div className="body">
                                            <ul>
                                                <li><CheckIcon />17 X 390 = 6630 W Tier 1 Panels</li>
                                                <li><CheckIcon />Upto 25 Years Solar Panel Warranty</li>
                                                <li><CheckIcon />5Kw Smart Inverter</li>
                                                <li><CheckIcon />Wifi Monitoring</li>
                                            </ul>
                                        </div>
                                        <div className="footer">
                                            <Link to="/contact-us"><ArrowForwardIcon /></Link>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            </Col>
                            <Col md={3} className="my-auto">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div className="pricing">
                                        <div className="header type-2">
                                            <img src={Kw10} alt="Kw10" />
                                            <hr />
                                            <h4>10kW</h4>
                                        </div>
                                        <div className="body">
                                            <ul>
                                                <li><CheckIcon />26 x 390 = 10140 W Tier 1 Panels</li>
                                                <li><CheckIcon />Upto 25 Years Solar Panel Warranty</li>
                                                <li><CheckIcon />8Kw Smart Inverter</li>
                                                <li><CheckIcon />Wifi Monitoring</li>
                                            </ul>
                                        </div>
                                        <div className="footer">
                                            <Link to="/contact-us"><ArrowForwardIcon /></Link>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            </Col>
                            <Col md={3} className="my-auto">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div className="pricing">
                                        <div className="header type-1">
                                            <img src={Kw13} alt="Kw13" />
                                            <hr />
                                            <h4>13.2kW</h4>
                                        </div>
                                        <div className="body">
                                            <ul>
                                                <li><CheckIcon />39 X 390 = 15210 W Tier 1 Panels</li>
                                                <li><CheckIcon />Upto 25 Years Solar Panel Warranty</li>
                                                <li><CheckIcon />10Kw Smart Inverter</li>
                                                <li><CheckIcon />Wifi Monitoring</li>
                                            </ul>
                                        </div>
                                        <div className="footer">
                                            <Link to="/contact-us"><ArrowForwardIcon /></Link>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            </Col>
                        </Row>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <Row className="justify-content-md-center">
                            <Col md={3} className="my-auto">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div className="pricing">
                                        <div className="header type-1">
                                            <img src={Kw5} alt="Kw5" />
                                            <hr />
                                            <h4>15kW</h4>
                                        </div>
                                        <div className="body">
                                            <ul>
                                                <li><CheckIcon />43 x 370 W = 15910 W Tier 1 Panels</li>
                                                <li><CheckIcon />Upto 25 Years Solar Panel Warranty</li>
                                                <li><CheckIcon />15 kW Smart Inverter</li>
                                                <li><CheckIcon />Wifi Monitoring</li>
                                            </ul>
                                        </div>
                                        <div className="footer">
                                            <Link to="/contact-us"><ArrowForwardIcon /></Link>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            </Col>
                            <Col md={3} className="my-auto">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div className="pricing">
                                        <div className="header type-2">
                                            <img src={Kw6} alt="Kw6" />
                                            <hr />
                                            <h4>30kW</h4>
                                        </div>
                                        <div className="body">
                                            <ul>
                                                <li><CheckIcon />86 x 370 W = 31820 W Tier 1 Panels</li>
                                                <li><CheckIcon />Upto 25 Years Solar Panel Warranty</li>
                                                <li><CheckIcon />15 kW + 15 kW Smart Inverter</li>
                                                <li><CheckIcon />Wifi Monitoring</li>
                                            </ul>
                                        </div>
                                        <div className="footer">
                                            <Link to="/contact-us"><ArrowForwardIcon /></Link>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            </Col>
                            <Col md={3} className="my-auto">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <div className="pricing">
                                        <div className="header type-1">
                                            <img src={Kw13} alt="Kw13" />
                                            <hr />
                                            <h4>50kW</h4>
                                        </div>
                                        <div className="body">
                                            <ul>
                                                <li><CheckIcon />143 x 370 W = 52910 W Tier 1 Panels</li>
                                                <li><CheckIcon />Upto 25 Years Solar Panel Warranty</li>
                                                <li><CheckIcon />25 kW + 25 kW Smart Inverter</li>
                                                <li><CheckIcon />Wifi Monitoring</li>
                                            </ul>
                                        </div>
                                        <div className="footer">
                                            <Link to="/contact-us"><ArrowForwardIcon /></Link>
                                        </div>
                                    </div>
                                </ScrollAnimation>
                            </Col>
                        </Row>
                    </TabPanel>
                </Box>
            </Container>
        </section>
    )
}

export default PricingProducts
