import { Link, useLocation } from 'react-router-dom'
import React, { useState } from 'react'

import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { Container } from 'react-bootstrap'
import Divider from '@mui/material/Divider'
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Logo from '../assets/images/logo/sundropsolar-logo.png'
import MenuIcon from '@mui/icons-material/Menu'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { menu } from '../Menu'

const Header = () => {
  let location = useLocation().pathname
  const [sideMenu, setSideMenu] = useState(false)

  function checkUrl(url) {
    if (location === url) {
      return 'active'
    } else {
      return null
    }
  }

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }
    setSideMenu({ sideMenu, left: open })
  }

  return (
    <div id='header'>
      <Container>
        {/* Mobile Menu */}
        <SwipeableDrawer
          open={sideMenu['left']}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <Box onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <img
              src={Logo}
              alt='Sundrop Solar Logo'
              className='img-fluid pt-2 pe-5 pb-2 ps-5'
            />
            <Divider />
            <List>
              {menu.map((menu, index) => (
                <a href={menu.path}>
                  <ListItem button key={index}>
                    <ListItemIcon>
                      <KeyboardReturnIcon className='fa-flip-horizontal' />
                    </ListItemIcon>
                    <ListItemText primary={menu.label} />
                  </ListItem>
                </a>
              ))}
            </List>
          </Box>
        </SwipeableDrawer>
        {/* END: Mobile Menu */}
        <Link to='/'>
          <img src={Logo} alt='Sundrop Solar Logo' className='img-fluid' />
        </Link>
        <Button
          onClick={toggleDrawer(true)}
          className='d-block d-sm-none menuicon'
        >
          <MenuIcon />
        </Button>
        <nav id='navbar' className='navbar right d-none d-sm-block'>
          <ul>
            {menu.map((menu, index) => {
              return (
                <li key={index} className={checkUrl(menu.path)}>
                  <a href={menu.path}>{menu.label}</a>
                </li>
              )
            })}
            <li>
              <a href='/contact-us' className='btn-2 btn-quote'>
                Get a Quote <ArrowForwardIcon />
              </a>
            </li>
          </ul>
        </nav>
      </Container>
    </div>
  )
}

export default Header
